import $ from 'jquery'
import { getCookie, setCookie } from '/lib/cookie'

$(function () {
  if ($('.page-template-pdp-lowes').length) {
    return
  }

  if (!getCookie('mg-announcement-banner')) {
    const $banner = $('.js-announcement-banner')
    setCookie('announceBannerActive', 'true', 1)
    let $height = $banner.height() + 20
    $('.main').css('margin-top', $height)
    $banner.slideDown()
    $('body').addClass('banner-active')
    $('.js-banner-close').click(function () {
      $banner.slideUp()
      setCookie('mg-announcement-banner', 'true', 1)
      setCookie('announceBannerActive', 'false', 1)
      $('body').removeClass('banner-active')
      $('.main').css('margin-top', '0px')
    })
  }
})

$(window).on('load resize', function (e) {
  if ($('body').hasClass('banner-active')) {
    let $banner = $('.js-announcement-banner')
    let $height = $banner.height() + 20
    $('.main').css('margin-top', $height)
  }
})
